@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

















































.card
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  background: white
  border-radius: $border_radius_l
  border-top: 1px solid $color_ink_lightest
  width: 220px
  height: 216px
  padding: 0
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2)

  &__icon
    padding-left: $size-s
    width: $size-xl
    height: $size-xl

  &__title
    margin: $size-s 0
    padding: 0 $size-s
    color: $color-ink
    font-weight: $font_weight_medium
    height: 50px

  &__button
    border-top: 1px solid $color_ink_lightest
    padding: $size-xs 0 0 $size-s
    width: 100%

.download-button
  margin-top: $size-xs
  width: 60px
  height: 28px
  font-size: $font_size_xs

