@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"


































































.sas-wrapper
  max-width: 1032px
  margin: 0 auto

.cards-container
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 12px 18px
  margin-top: 24px

.empty-instructions
  width: 100%
  margin-top: $size-l
  text-align: center

